import BaseButton from "@/components/Button";
import { SVGArrowRight } from "@/lib/svg";
import { forwardRef } from "react";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 1.5rem;
  grid-column: 1 / -1;
  margin-top: 1rem;
  ${({ theme }) => theme.mediaQueries.laptop} {
    grid-column: 3 / -1;
    padding: 0 0.75rem;
  }
`;

export const Button = forwardRef<
  HTMLAnchorElement,
  { children: string; href?: string, target?: string }
>(function Button(props, ref) {
  return (
    <BaseButton
      ref={ref}
      as="a"
      variant="primaryBoxed"
      icon={<SVGArrowRight />}
      {...props}
    />
  );
});

export const StyledButton = styled(Button)`
  opacity: 0;
  transform: translateY(20px);
`;
