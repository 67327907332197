import React from "react";
import ExternalLink, { ExternalLinkProps } from "./ExternalLink";
import InternalLink, { InternalLinkProps } from "./InternalLink";
import DownloadLink, { DownloadLinkProps } from "./DownloadLink";
import ProductSearch, { ProductSearchProps } from "./ProductSearch";

export type CallToActionProps =
  | InternalLinkProps
  | ExternalLinkProps
  | DownloadLinkProps
  | ProductSearchProps;

const CallToAction: React.FC<CallToActionProps> = (props) => {
  switch (props.action) {
    case "internalLink":
      return <InternalLink {...props} />;
    case "externalLink":
      return <ExternalLink {...props} />;
    case "downloadLink":
      return <DownloadLink {...props} />;
    case "productSearch":
      return <ProductSearch {...props} />;
    default:
      return null;
  }
};

export default CallToAction;
