import { Button } from "./styles";

export type ExternalLinkProps = {
  _type: "externalLink";
  title: string | null;
  url: string | null;
  blank: boolean;
};

/** @deprecated */
const ExternalLink: React.FC<ExternalLinkProps> = (props) => {
  const { url, title, blank } = props;
  if (!url || !title) return null;
  return <Button href={url} target={`${blank ? "_blank" : ""}`}>{title}</Button>;
};

export default ExternalLink;
