/* eslint-disable jsx-a11y/alt-text */
import gsap, { SplitText } from "@/lib/gsap";
import React, { useEffect, useRef } from "react";
import {
  Container,
  Excerpt,
  Heading,
  CenteredHeading,
  ButtonsContainer,
} from "./styles";
import Button, { ButtonProps } from "./Button";
import Image, { ImageProps } from "./Image";
import Video, { VideoProps } from "./Video";
import Cover from "./Cover";
import { AppLinkProps } from "@/components/AppLink";
import _SplitText from "gsap/SplitText";
import { debounce } from "lodash";

export type HeroProps = {
  heading: string | null;
  headingStyle?: string;
  headingPosition: string | null;
  excerpt: string | null;
  buttons: ButtonProps[] | null;
  media: (ImageProps | VideoProps)[] | null;
  internalLink?: AppLinkProps & {
    _type: "internalLink";
  };
};

const Hero: React.FC<HeroProps> = (props) => {
  const { buttons, heading, headingStyle = "H1", headingPosition, excerpt, media, internalLink } =
    props;

  const [medium] = media || [];
  const isVideo = medium?._type == "video";
  const aspectRatio = isVideo ? 9 / 16 : 1 / 2;

  const containerRef = useRef<HTMLDivElement>(null);
  const headingRef = useRef<HTMLHeadingElement>(null);
  const coverRef = useRef<HTMLDivElement>(null);
  const excerptRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<HTMLDivElement>(null);

  const headingTextRef = useRef<_SplitText | null>(null);
  const excerptTextRef = useRef<_SplitText | null>(null);

  useEffect(() => {
    // Create closure around mutable ref
    const container = containerRef.current;
    const heading = headingRef.current;
    const cover = coverRef.current;
    const excerpt = excerptRef.current;
    const buttons = buttonsRef.current;

    const prepareAnimation = () => {
      if (heading) {
        headingTextRef.current = new SplitText(heading, {
          type: "lines",
          linesClass: "lines",
        });
        gsap.set(heading, { opacity: 1.0 });
      }
      if (excerpt) {
        excerptTextRef.current = new SplitText(excerpt, {
          type: "lines",
          linesClass: "lines",
        });
        gsap.set(excerpt, { opacity: 1.0 });
      }
    };

    prepareAnimation();

    const revertText = debounce(() => {
      if (headingTextRef.current) {
        headingTextRef.current.revert();
      }
      if (excerptTextRef.current) {
        excerptTextRef.current?.revert();
      }
    }, 30);

    window.addEventListener("resize", revertText);

    const runAnimation = () => {
      const timeline = gsap.timeline({
        default: {
          duration: 0.3,
        },
        scrollTrigger: {
          trigger: container,
          start: "top 75%",
          toggleActions: "play pause resume reset",
        },
      });
      if (headingTextRef.current) {
        timeline.to(headingTextRef.current.lines, {
          opacity: 1.0,
          translateY: 0,
          stagger: {
            amount: 0.3,
          },
        });
      }
      if (cover) {
        timeline.to(
          cover,
          {
            opacity: 1.0,
            translateY: 0,
          },
          "<25%"
        );
      }
      if (excerptTextRef.current) {
        timeline.to(
          excerptTextRef.current.lines,
          {
            opacity: 1.0,
            translateY: 0,
            stagger: { amount: 0.3 },
          },
          "<25%"
        );
      }
      if (buttons) {
        timeline.to(
          buttons,
          {
            opacity: 1.0,
            translateY: 0,
          },
          "<25%"
        );
      }
      return timeline;
    };

    const timeline = runAnimation();

    return function cleanup() {
      window.removeEventListener("resize", revertText);
      timeline.kill();
    };
  }, []);

  return (
    <>
      <Container ref={containerRef}>
        {heading && headingPosition !== "center" && (
          <Heading ref={headingRef} $headingStyle={headingStyle}>{heading}</Heading>
        )}

        {medium && (
          <Cover
            ref={coverRef}
            aspectRatio={aspectRatio}
            internalLink={internalLink}
            headingPosition={headingPosition}
          >
            {heading && headingPosition === "center" && (
              <CenteredHeading ref={headingRef}>{heading}</CenteredHeading>
            )}
            {isVideo ? (
              <Video {...medium} headingPosition={headingPosition} />
            ) : (
              <Image {...medium} />
            )}
          </Cover>
        )}

        {excerpt && (
          <Excerpt
            ref={excerptRef}
            dangerouslySetInnerHTML={{
              __html: excerpt.replace(/\n/g, "<br />"),
            }}
          ></Excerpt>
        )}
        {buttons && (
          <ButtonsContainer ref={buttonsRef}>
            {buttons?.map((button) => (
              <Button key={button._key} {...button} />
            ))}
          </ButtonsContainer>
        )}
      </Container>
    </>
  );
};

export default Hero;
