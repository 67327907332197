import Plyr, { APITypes } from "plyr-react";
import { forwardRef } from "react";
import { Wrapper } from "./styles";

type Props = {
  className?: string;
  src: string;
  poster: string;
};

const VideoPlayer = forwardRef<APITypes, Props>(function VideoPlayer(
  props,
  ref
) {
  const { className, src, poster } = props;
  return (
    <Wrapper className={className}>
      <Plyr
        ref={ref}
        source={{
          type: "video",
          poster,
          sources: [
            {
              src,
            },
          ],
        }}
        options={{
          controls: ["play", "play-large", "progress", "mute", "volume"],
          fullscreen: { enabled: false },
          iconUrl: "/plyr.svg",
        }}
      />
    </Wrapper>
  );
});

export default VideoPlayer;
