import gsap from "@/lib/gsap";
import {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { Container } from "./styles";

export type CursorProps = PropsWithChildren<{
  className?: string;
  width: number;
  height: number;
}>;

export type CursorAPI = {
  moveTo: (x: number, y: number) => GSAPTween;
  position: (x: number, y: number) => GSAPTween;
  animate: (vars: GSAPTweenVars) => GSAPTween;
};

/**
 * Renders an element that exposes an imperative API to move it around
 * @see https://gist.github.com/fibonacid/f52524c02debe4c89603614b30a5d4ae
 */
const Cursor = forwardRef<CursorAPI, CursorProps>(function Cursor(props, ref) {
  const { className, width, height, children } = props;
  const container = useRef<HTMLSpanElement>(null);

  useImperativeHandle(ref, () => {
    const getX = (x: number) => x - width * 0.5;
    const getY = (y: number) => y - height * 0.5;
    return {
      moveTo(x, y) {
        return gsap.to(container.current, {
          x: getX(x),
          y: getY(y),
          duration: 0.1,
          ease: "power2.out",
        });
      },
      animate(vars) {
        return gsap.to(container.current, vars);
      },
      position(x, y) {
        return gsap.set(container.current, {
          x: getX(x),
          y: getY(y),
        });
      },
    };
  });

  useEffect(() => {
    () => {
      gsap.killTweensOf(container.current);
    };
  }, []);

  return (
    <Container
      ref={container}
      $width={width}
      $height={height}
      className={className}
    >
      {children}
    </Container>
  );
});

export default Cursor;
