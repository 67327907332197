import React from "react";
import ButtonComplex, { ButtonComplexProps } from "@/components/ButtonComplex";

export type ButtonProps = ButtonComplexProps

const Button: React.FC<ButtonComplexProps> = (props) => {
  return <ButtonComplex {...props} />
};

export default Button;
