import styled from "styled-components";
import VideoPlayer from "@/components/VideoPlayer";

export const Container = styled.video<{ $headingPosition: string | null }>`
  position: absolute;
  inset: 0;
  width: 100%;
  height: ${({ $headingPosition }) =>
    $headingPosition === "center" ? "400px" : "100%"};

  object-fit: cover;
  ${({ theme }) => theme.mediaQueries.tablet} {
    height: 100%;
  }
`;

export const Player = styled(VideoPlayer)`
  position: absolute;
  inset: 0;
  .plyr--video {
    position: absolute;
    inset: 0;
  }
`;
