import MouseCursor from "@/components/MouseCursor";
import { SVGCarouselPlus, SVGCarouselSwipe } from "@/lib/svg";
import styled, { css } from "styled-components";

export const Container = styled(MouseCursor)`
  display: none;
  ${({ theme }) => theme.mediaQueries.laptop} {
    background: ${({ theme }) => theme.colors.red};
    border-radius: 50%;
    display: grid;
    place-items: center;
    opacity: 0;
    //  translate(-100%, -100%) is necessary as you are overriding the default position of the cursor by setting the transform!
    transform: scale(0.75) translate(-150%, -150%);
  }
`;

export const iconStyles = css`
  width: 42px;
  height: 42px;
`;

export const PlusIcon = styled(SVGCarouselPlus)`
  ${iconStyles}
`;
export const SwipeIcon = styled(SVGCarouselSwipe)`
  ${iconStyles}
`;
