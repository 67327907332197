import CallToAction from "@/components/CallToAction";

export type ExternalLinkProps = {
  _type: "callToAction";
  icon: string | null;
  variant: string | null;
  action: "externalLink" | null;
  externalLink: {
    title: string | null;
    url: string | null;
    blank: boolean;
  };
};

const ExternalLink: React.FC<ExternalLinkProps> = (props) => {
  const { icon, variant, externalLink } = props;
  return (
    <CallToAction
      as="a"
      icon={icon as any}
      variant={variant as any}
      externalLink={externalLink as any}
      target={`${externalLink.blank ? "_blank" : ""}`}
      action="externalLink"
    />
  );
};

export default ExternalLink;
