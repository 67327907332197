import { Container, PlusIcon, SwipeIcon } from "./styles";
import { Transition, TransitionStatus } from "react-transition-group";
import { useRef } from "react";
import { CursorAPI } from "@/components/Cursor";

export type PointerProps = {
  isActive: boolean;
  icon?: "plus" | "swipe";
};

const TIMEOUT_MILLIS = 500;
const TIMEOUT_SECONDS = TIMEOUT_MILLIS / 1000;

const StickyPointer: React.FC<PointerProps> = (props) => {
  const { isActive, icon = "plus" } = props;
  const cursorRef = useRef<CursorAPI>(null);

  const onEnter = () => {
    cursorRef.current?.animate({
      opacity: 1,
      scale: 1,
      duration: TIMEOUT_SECONDS,
      ease: "power2.out",
    });
  };
  const onExit = () => {
    cursorRef.current?.animate({
      opacity: 0,
      scale: 0.75,
      duration: TIMEOUT_SECONDS,
      ease: "power2.out",
    });
  };

  return (
    <Transition
      in={isActive}
      timeout={TIMEOUT_SECONDS}
      onEnter={onEnter}
      onExit={onExit}
      nodeRef={{ current: null }}
      mountOnEnter
      unmountOnExit
    >
      {() => (
        <Container ref={cursorRef} width={160} height={160}>
          {icon === "plus" && <PlusIcon />}
          {icon === "swipe" && <SwipeIcon />}
        </Container>
      )}
    </Transition>
  );
};

export default StickyPointer;
