import CallToAction from "@/components/CallToAction";

export type ProductSearchProps = {
  _type: "callToAction";
  icon: string | null;
  variant: string | null;
  action: "productSearch" | null;
  productSearch: {
    title: string | null;
    page: {
      _type: string;
      slug: string | null;
    } | null;
  };
};

const productSearch: React.FC<ProductSearchProps> = (props) => {
  const { icon, variant, productSearch } = props;
  return (
    <CallToAction
      as="a"
      icon={icon as any}
      variant={variant as any}
      productSearch={productSearch as any}
      action="productSearch"
    />
  );
};

export default productSearch;
