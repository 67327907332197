import CallToAction from "@/components/CallToAction";

export type InternalLinkProps = {
  _type: "callToAction";
  icon: string | null;
  variant: string | null;
  action: "internalLink" | null;
  internalLink: {
    title: string | null;
    page: {
      _type: string;
      slug: string | null;
    } | null;
  };
};

const InternalLink: React.FC<InternalLinkProps> = (props) => {
  const { icon, variant, internalLink } = props;
  return (
    <CallToAction
      as="a"
      icon={icon as any}
      variant={variant as any}
      internalLink={internalLink as any}
      action="internalLink"
    />
  );
};

export default InternalLink;
