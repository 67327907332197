import { Container, Player } from "./styles";

export type VideoProps = {
  _type: "video";
  autoplay: boolean;
  loop: boolean | null;
  posterImage: {
    asset: {
      url: string;
    };
  };
  file: {
    asset: {
      url: string;
    };
  };
  headingPosition: string | null;
};

const Video: React.FC<VideoProps> = (props) => {
  const { posterImage, file, autoplay, loop, headingPosition } = props;

  if (!file.asset?.url) return null;
  const poster = posterImage?.asset?.url || file.asset.url + "#t=0.1";

  if (autoplay) {
    return (
      <Container
        playsInline
        loop={!!loop}
        muted
        src={file.asset.url}
        poster={poster}
        autoPlay
        $headingPosition={headingPosition}
      ></Container>
    );
  } else {
    return <Player src={file.asset.url} poster={poster} />;
  }
};

export default Video;
