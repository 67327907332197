import styled from "styled-components";
import { ImageWrapper as _ImageWrapper } from "@/lib/styles/theme";
import { H1, H2, P } from "@/lib/styles/text";
import { h1, h2 } from "@/lib/styles/helpers";
import { forwardRef } from "react";
import CallToAction from "@/components/Button";
import { SVGArrowRight } from "@/lib/svg";
import { pageMargins } from "@/lib/styles/helpers";

export const Container = styled.div`
  ${pageMargins};

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${({ theme }) => theme.mediaQueries.laptop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const ImageWrapper = styled(_ImageWrapper)`
  padding-bottom: 50%;
  position: relative;
  margin-bottom: 3rem; // TODO remove?
  grid-column: 1 / -1;

  opacity: 0;
  transform: translateY(100px);
`;

export const Heading = styled(H1)<{ $headingStyle: string }>`
  ${({ $headingStyle }) => {
    switch ($headingStyle) {
      case "H2":
        return h2;
      default:
        return h1;
    }
  }}; 
  
  margin-bottom: 2.5rem;
  grid-column: 1 / -1;

  opacity: 0;
  .lines {
    opacity: 0;
    transform: translateY(50px);
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    margin: 0 7.185rem 2.5rem;
    max-width: 70rem;
  }
`;

export const CenteredHeading = styled(H2)`
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  justify-content: center;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.white};
  flex-wrap: wrap;
  text-align: left;
  
  // 'max-width' avoids the text to touch the margins
  max-width: 90%;

  // 'min-width avoids to have one word per line forcing to fill at least some % of the width.
  // Be aware that, the higher the value the higher is the possibility that the text will not
  //   look centered. It's easier to be seen than imagined. See the homepage for some real use.
  min-width: 70%;   
  margin: 0 auto;
  transform: translateX(-50%);
  opacity: 0;

  ${({ theme }) => theme.mediaQueries.tablet} {
    min-width: 40%;
  }
  
  .lines {
    opacity: 0;
    transform: translateY(50px);
    white-space: nowrap;
  }
`;

export const Excerpt = styled(P)`
  grid-column: 1 / -1;
  background-color: ${({theme})=> theme.colors.white};
  opacity: 0;
  .lines {
    opacity: 0;
    transform: translateY(50px);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    background-color: ${({ theme }) => theme.colors.white};
    grid-column: 3 / -1;
    padding: 0.5rem 0.75rem;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 1.5rem;
  grid-column: 1 / -1;
  margin-top: 1rem;
  flex-wrap: wrap;

  ${({ theme }) => theme.mediaQueries.laptop} {
    grid-column: 3 / -1;
    padding: 0 0.75rem;
  }
`;
