import React from "react";
import ExternalLink, { ExternalLinkProps } from "./ExternalLink";
import InternalLink, { InternalLinkProps } from "./InternalLink";
import CallToAction, { CallToActionProps } from "./CallToAction";

export type ButtonComplexProps = { _key: string } & (
  | InternalLinkProps
  | ExternalLinkProps
  | CallToActionProps
);

const ButtonComplex: React.FC<ButtonComplexProps> = (props) => {
  switch (props._type) {
    case "internalLink":
      return <InternalLink {...props} />;
    case "externalLink":
      return <ExternalLink {...props} />;
    case "callToAction":
      return <CallToAction {...props} />;
    default:
      return null;
  }
};

export default ButtonComplex;
