import Button, { ButtonProps } from "@/components/Button";
import { SVGArrowRight, SVGDownload, SVGLens, SVGPlus } from "@/lib/svg";
import Link from "next/link";
import React from "react";
import AppLink, { AppLinkProps } from "../AppLink";

const iconMap = {
  arrowRight: <SVGArrowRight />,
  download: <SVGDownload />,
  lens: <SVGLens />,
  plus: <SVGPlus />,
} as const;

type IconMapKey = `${keyof typeof iconMap}`;

type InternalLink = AppLinkProps & {
  _type: "internalLink";
  title: string;
};

type ExternalLink = {
  _type: "externalLink";
  title: string;
  url: string;
  blank: boolean;
};

type DownloadLink = {
  _type: "downloadLink";
  title: string;
  file: {
    asset: {
      url: string;
    };
  };
};

type ProductSearch = AppLinkProps & {
  _type: "productSearch";
  title: string;
};

export type CallToActionProps = Omit<ButtonProps, "icon"> & {
  icon: IconMapKey;
} & (
    | {
        action: "internalLink";
        internalLink: Omit<InternalLink, "_type"> | null;
      }
    | {
        action: "externalLink";
        externalLink: Omit<ExternalLink, "_type"> | null;
      }
    | {
        action: "downloadLink";
        downloadLink: Omit<DownloadLink, "_type"> | null;
      }
    | {
        action: "productSearch";
        productSearch: Omit<ProductSearch, "_type"> | null;
      }
  );

const CallToAction: React.FC<CallToActionProps> = (props) => {
  const { variant, action, style, icon } = props;
  const iconElement = iconMap[icon];

  const buttonProps: ButtonProps = {
    as: "a",
    icon: iconElement,
    variant,
    style,
  };

  if (action === "internalLink" && props.internalLink) {
    const { internalLink } = props;
    return (
      <AppLink page={internalLink.page} params={internalLink.params}>
        <Button {...buttonProps}>{internalLink.title}</Button>
      </AppLink>
    );
  } else if (action === "externalLink" && props.externalLink) {
    const { externalLink } = props;
    if (externalLink.url) {
      return (
        <Button {...buttonProps} href={externalLink.url} target={`${externalLink.blank ? "_blank" : ""}`} {...buttonProps}>
          {externalLink.title}
        </Button>
      );
    }
  } else if (action === "downloadLink" && props.downloadLink) {
    const { downloadLink } = props;
    const fileURL = downloadLink.file?.asset?.url;
    if (fileURL) {
      return (
        <Button
          {...buttonProps}
          href={fileURL}
          target="_blank"
          rel="noreferrer"
          {...buttonProps}
        >
          {downloadLink.title}
        </Button>
      );
    }
  } else if (action === "productSearch") {
    const { productSearch } = props;
    if (productSearch) {
      return (
        <AppLink page={productSearch.page} params={"q="}>
          <Button {...buttonProps}>{productSearch.title}</Button>
        </AppLink>
      );
    }
  }
  return null;
};

export default CallToAction;
