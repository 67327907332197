import CallToAction from "@/components/CallToAction";

export type DownloadLinkProps = {
  _type: "callToAction";
  icon: string | null;
  variant: string | null;
  action: "downloadLink" | null;
  downloadLink: {
    title: string | null;
    file: {
      asset: {
        url: string;
      };
    } | null;
  } | null;
};

const DownloadLink: React.FC<DownloadLinkProps> = (props) => {
  const { icon, variant, downloadLink } = props;
  return (
    <CallToAction
      as="a"
      icon={icon as any}
      variant={variant as any}
      downloadLink={downloadLink as any}
      action="downloadLink"
    />
  );
};

export default DownloadLink;
