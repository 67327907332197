import AppLink, { AppLinkProps } from "@/components/AppLink";
import StickyPointer from "@/components/StickyPointer";
import { ForwardedRef, forwardRef, PropsWithChildren, useEffect, useState } from "react";
import { Container, LinkContainer } from "./styles";

type Props = PropsWithChildren<{
  aspectRatio: number;
  internalLink?: AppLinkProps | null;
  headingPosition: string | null;
}>;

const Cover = forwardRef<HTMLDivElement | HTMLAnchorElement, Props>(
  function Cover(props, ref) {
    const { children, aspectRatio, internalLink, headingPosition } = props;
    const [pointerActive, setPointerActive] = useState(false);

    const commonProps = {
      $hasLoaded: true,
      $aspectRatio: aspectRatio,
      $headingPosition: headingPosition
    };

    if (internalLink && internalLink?.page) {
      return (
        <>
          <StickyPointer isActive={pointerActive} />
          <AppLink page={internalLink.page} params={internalLink.params}>
            <LinkContainer
              as="a"
              ref={ref as ForwardedRef<HTMLAnchorElement>}
              onMouseEnter={() => setPointerActive(true)}
              onMouseLeave={() => setPointerActive(false)}
              {...commonProps}
            >
              {children}
            </LinkContainer>
          </AppLink>
        </>
      );
    }
    return (
      <Container
        as="div"
        ref={ref as ForwardedRef<HTMLDivElement>}
        {...commonProps}
      >
        {children}
      </Container>
    );
  }
);

export default Cover;
