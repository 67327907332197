import styled from "styled-components";

export const Container = styled.span<{ $width: number; $height: number }>`
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-150%, -150%);
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  display: block;
  z-index: 9999;
  pointer-events: none;
`;
