import React from "react";
import styled, { css } from "styled-components";
import { ImageWrapper } from "../styles";

export const Container = styled(ImageWrapper)<{
  $aspectRatio: number;
  $headingPosition: string | null;
}>`
  width: 100%;
  padding-bottom: ${({ $headingPosition, $aspectRatio }) =>
    $headingPosition === "center" ? "400px" : `calc(${$aspectRatio} * 100%)`};
  position: relative;
  margin-bottom: 3rem; // TODO remove?
  grid-column: 1 / -1;
  opacity: 0;
  transform: translateY(100px);
  ${({ theme }) => theme.mediaQueries.tablet} {
    padding-bottom: calc(${({ $aspectRatio }) => $aspectRatio} * 100%);
  }
`;

export const LinkContainer = styled(Container)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    // hide cursor only on laptop, check MouseCursor breakpoint
    cursor: none;
  }
`;
