import styled from "styled-components";

// Style applied to the icon container:
// padding: calc(var(--plyr-control-spacing, 10px)*1.5);

// To calculate the padding, we need to use the following formula:
// ---------------------------------------------------------------
// padding = control_spacing_value * 1.5
// total_width = padding * 2 + icon_width;
// control_spacing_value = (total_width - icon_width) / 3

// Same thing but in css:
// -------------------------------------------------------------------------
// --plyr-control-spacing: calc((40px - var(--plyr-control-icon-size)) / 3);
// where `40px` is the total width of the icon container from the design

/**
 *
 * @see https://gist.github.com/fibonacid/a5eddf28d1d60212956dd34fdb4062b6
 */
export const Wrapper = styled.div`
  /* The primary UI color. */
  --plyr-color-main: ${({ theme }) => theme.colors.red};

  .plyr__control.plyr__control--overlaid {
    --plyr-control-icon-size: 20px;
    --plyr-control-spacing: calc((40px - var(--plyr-control-icon-size)) / 3);
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    .plyr__control.plyr__control--overlaid {
      --plyr-control-icon-size: 32px;
      --plyr-control-spacing: calc((80px - var(--plyr-control-icon-size)) / 3);
    }
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    .plyr__control.plyr__control--overlaid {
      --plyr-control-icon-size: 43px;
      --plyr-control-spacing: calc((160px - var(--plyr-control-icon-size)) / 3);
    }
  }
`;
