import { imageLoader } from "@/lib/sanity/client";
import NextImage from "next/image";

export type ImageProps = {
  _type: "image";
  asset: {
    url: string;
  };
};

const Image: React.FC<ImageProps> = (props) => {
  const { asset } = props;
  if (asset.url) {
    return (
      <NextImage
        src={asset.url}
        layout="fill"
        objectFit="cover"
        alt={""}
        loader={imageLoader}
        priority
      />
    );
  } else {
    return null;
  }
};

export default Image;
