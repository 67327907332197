import type { CursorAPI } from "@/components/Cursor";
import Cursor from "@/components/Cursor";
import { forwardRef, PropsWithChildren, useEffect } from "react";

export type MouseCursorProps = PropsWithChildren<{
  width: number;
  height: number;
  className?: string;
}>;

const MouseCursor = forwardRef<CursorAPI, MouseCursorProps>(
  function MouseCursor(props, ref) {
    const { width, height, className, children } = props;

    useEffect(() => {
      if (!ref || typeof ref !== "object") return;
      let cursorInitialized = false;

      const handleMouseMove = (event: MouseEvent) => {
        const x = event.clientX;
        const y = event.clientY;

        if (cursorInitialized) {
          ref.current?.moveTo(x, y);
        } else {
          // On the first mouse move event we want to position
          // the cursor directly so that we don't see a trail
          // of it coming from the top left corner.
          ref.current?.position(x, y);
          cursorInitialized = true;
        }
      };

      const handleDrag = (event: DragEvent) => {
        const x = event.clientX;
        const y = event.clientY;
        // Right before drag is over the mouse
        // coordinates go to [0,0].
        if (x > 0 && y > 0) {
          // Skip animation on last drag event
          // to prevent the cursor from jumping to
          // the top right corner.
          ref.current?.moveTo(x, y);
        }
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("drag", handleDrag);
      // Unregister listener when component unmounts
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("drag", handleDrag);
      };
    }, [ref]);

    return (
      <Cursor ref={ref} width={width} height={height} className={className}>
        {children}
      </Cursor>
    );
  }
);

export default MouseCursor;
