// import { resolveInternalLink } from "@/lib/helpers";
// import Link from "next/link";
// import { useMemo } from "react";
// import { Button } from "./styles";

export type InternalLinkProps = {
  _type: "internalLink";
  title: string | null;
  page: {
    _type: string;
    slug: string | null;
  } | null;
};

/** @deprecated */
const InternalLink: React.FC<InternalLinkProps> = (props) => {
  return null;
  // const { title, page } = props;

  // const href = useMemo(() => {
  //   if (page?._type) {
  //     return resolveInternalLink(page?._type, page?.slug);
  //   }
  // }, [page?._type, page?.slug]);

  // if (!href || !title) return null;

  // return (
  //   <Link href={href} passHref>
  //     <Button>{title}</Button>
  //   </Link>
  // );
};

export default InternalLink;
